import * as React from "react"
import MainLayout from "../layouts/MainLayout";
import {Container} from "react-bootstrap";
import Typography from "../components/common/Typography";

// markup
const NotFoundPage = () => {
  return (
    <MainLayout>
      <Container className="text-center py-5">
          <Typography component="h1">404</Typography>
          <Typography component="p">Looks like the page you're looking for doesn't exist!</Typography>
      </Container>
    </MainLayout>
  )
}

export default NotFoundPage
